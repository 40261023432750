import React, { useEffect } from 'react';
import { useSidebar } from '../components/info_sidebar/SidebarContext';
import SpotSelectorMap from '../components/map/SpotSelectorMap';

const SpotSelector: React.FC = () => {
  const { setContent, toggleSidebar } = useSidebar();

  useEffect(() => {
    setContent(
      <div>
      </div>
    );
    toggleSidebar();
  }, [setContent]);

  return (
    <SpotSelectorMap />
  );
};

export default SpotSelector;
