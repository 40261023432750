// Function to check if the app is being viewed on a mobile device
export const getDeviceType = () => {
  const desktopDeviceTypes = [
    "Macintosh",
    "Windows",
    "Linux",
    "X11",
    "CrOS",
  ];

  const mobileDeviceTypes = [
    "Android",
    "webOS",
    "iPhone",
    "iPad",
    "iPod",
    "BlackBerry",
    "Windows Phone",
    "Opera Mini",
    "IEMobile",
    "Mobile"
  ];

  // Check if the device type is a desktop
  const isDesktop = desktopDeviceTypes.some((deviceType) =>
    window.navigator.userAgent.includes(deviceType)
  );

  // Check if the device type is a mobile device
  const isMobile = mobileDeviceTypes.some((deviceType) =>
    window.navigator.userAgent.includes(deviceType)
  );

  // Return the device type
  return { isPortable: !isDesktop, isDesktop: isDesktop, isMobile: isMobile };
}