import React from "react";
import './ExpediaStayBookingWidget.scss';
import { getDeviceType } from "../../../hooks/useGetDeviceType";

const StayBookingWidget: React.FC = () => {
  // State to track if the app is being viewed on a mobile device
  const { isPortable } = getDeviceType();

  return (
    <div id="searchWidget" className="stay-search-widget">
      <iframe
        className={isPortable ? "stay-widget-iframe-portable" : "stay-widget-iframe"}
        id="widgetIframe"
        src="https://www.expedia.com/marketing/widgets/searchform/widget?wtt=5&tp1=1101l45n9u&tp2=stay-test&lob=H&des=Sydney&wbi=1&olc=000000&whf=4&hfc=C7C7C7&wif=4&ifc=000000&wbc=FFCB00&wbf=4&bfc=3D3100&wws=2&sfs=H600FW600F&langid=1033"
        title="Expedia Stay Booking Widget"
        aria-label="Expedia Stay Booking Widget"
        onError={(e) => console.error("Failed to load Expedia widget", e)}
      ></iframe>
    </div>
  );
};

export default StayBookingWidget;