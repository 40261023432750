import { apiURL } from "../const";
import { CountryInterface } from "../interfaces/CountryInterface";

export async function countryCentreCoordinates(): Promise<CountryInterface[]> {
  try {
    const response = await fetch(`${apiURL}/api/country-coordinates`);
    if (!response.ok) {
      throw new Error('Failed to fetch countries coordinates');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return [];
  }
}
