import React from 'react';
import NavMenu from './NavMenu';
import SpotSelectorTypeBar from '../spot_selector/SpotSelectorTypeBar';
import { spotsType } from '../../constants/SpotSelectorType';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useIsSpotSelector } from '../../hooks/useGetPages';
import './HeaderMenu.scss';

const HeaderMenu: React.FC = () => {
  const handleAddSpotTypeToSessionStorage = (type: string) => {
    const existingSpotTypes = sessionStorage.getItem('selectedSpotTypes');
    const spotTypesArray = existingSpotTypes ? JSON.parse(existingSpotTypes) : [];
    if (!spotTypesArray.includes(type)) {
      spotTypesArray.push(type);
      sessionStorage.setItem('selectedSpotTypes', JSON.stringify(spotTypesArray));
    }
    window.dispatchEvent(new Event('storage'));
  };
  
  const createSpotSelectorTypeDropdown = () => {
    return (
      <Dropdown>
        <MenuButton variant="solid">Spot Types</MenuButton>
        <Menu variant="solid">
          {Object.keys(spotsType).map((spotTypeKey) => (
            <MenuItem key={spotTypeKey} onClick={() => handleAddSpotTypeToSessionStorage(spotTypeKey)}>
              {spotsType[spotTypeKey]}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    );
  };

  return (
    <header className="header">
      <div className="header-nav">
        <NavMenu />
        {useIsSpotSelector() && createSpotSelectorTypeDropdown()}
      </div>
      <div className="header-spot-type-list">
        <SpotSelectorTypeBar />
      </div>
    </header>
  );
};

export default HeaderMenu;