import React, { useEffect } from 'react';
import { useSidebar } from '../components/info_sidebar/SidebarContext';
import SafetyMap from '../components/map/SafetyMap';

const Safety: React.FC = () => {
  const { setContent, toggleSidebar, visible } = useSidebar();

  useEffect(() => {
    setContent(
      <div>
      </div>
    );
    visible && toggleSidebar();
  }, [setContent]);

  return (
    <SafetyMap />
  );
};

export default Safety;
