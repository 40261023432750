import axios from "axios";
import { SafetyMapData } from "../interfaces/SafetyInterface";
import { countryCentreCoordinates } from "./CountryCentreCoordinates";
import { apiURL } from "../const";

export const fetchAndDisplaySafetyData = async (map: mapboxgl.Map) => {
  try {
    const response = await axios.get('https://www.travel-advisory.info/api');
    const countrySafetyData = Object.values(response.data.data).map((item: any) => ({
      code: item.iso_alpha2,
      sft: item.advisory.score,
    }));
    displaySafetyCountries(map, countrySafetyData);
  } catch (error) {
    alert('Sorry, we are unable to show safety data at the moment');
  }
};

const displaySafetyCountries = (map: mapboxgl.Map, safetyData: SafetyMapData[]) => {
  const matchExpression = ['match', ['get', 'iso_3166_1']];
  ensureCountriesSource(map);

  for (const row of safetyData) {
    let color = `rgb(255, 255, 255)`;

    if (row.sft >= 4.5) {
      color = 'rgb(255,80,80)';
    } else if (row.sft >= 3.5) {
      color = 'rgb(220,230,30)';
    } else if (row.sft >= 2.5) {
      color = 'rgb(50,80,255)';
    } else if (row.sft >= 0) {
      color = 'rgb(102, 220, 153)';
    }
    matchExpression.push(row.code, color);
  }
  matchExpression.push('rgba(255, 255, 255, 0)');

  // Add the safety layer to the map
  map.on('sourcedata', (e) => {
    if (e.sourceId === 'countries' && !map.getLayer('safety') && map.isSourceLoaded('countries')) {
      map.addLayer({
        id: 'safety',
        type: 'fill',
        source: 'countries',
        'source-layer': 'country_boundaries',
        paint: {
          'fill-color': matchExpression as any,
        },
        layout: {
          visibility: 'visible',
        },
      }, 'admin-1-boundary-bg');
    }
  });
};

const ensureCountriesSource = (map: mapboxgl.Map) => {
  if (!map.getSource('countries')) {
    map.addSource('countries', {
      type: 'vector',
      url: 'mapbox://mapbox.country-boundaries-v1',
    });
  }
};

export const handleCountryClick = async (
  map: mapboxgl.Map,
  e: mapboxgl.MapMouseEvent,
  setContent: (content: React.ReactNode) => void,
  toggleSidebar: () => void
) => {
  // Check if the 'safety' layer exists
  if (!map.getLayer('safety')) {
    return;
  }

  const features = map.queryRenderedFeatures(e.point, { layers: ['safety'] });
  if (features && features.length) {
    const countryCoords = await countryCentreCoordinates();

    if (!countryCoords) return;

    let countryCode = '';
    let countryName = '';

    if (features[0].properties) {
      countryCode = features[0].properties.iso_3166_1;
      countryName = features[0].properties.name_en;
    }

    for (const country of countryCoords) {
      if (country.code === countryCode) {
        const [longitude, latitude] = country.coord;
        let zoomLevel;

        if (countryCode === 'RU' || countryCode === 'CA' || countryCode === 'US' || countryCode === 'GL') {
          zoomLevel = 2;
        } else {
          zoomLevel = 3;
        }

        map.flyTo({
          center: [longitude, latitude],
          zoom: zoomLevel,
          essential: true,
        });

        // Update the InfoSidebar content and toggle it
        toggleSidebar();
        
        await manageSafetyInfoBar(countryCode, countryName, setContent, toggleSidebar);
        break;
      }
    }
  }
};

const manageSafetyInfoBar = async (
  countryCode: string,
  countryName: string,
  setContent: (content: React.ReactNode) => void,
  toggleSidebar: () => void
) => {
  try {
    const response = await axios.get('https://www.travel-advisory.info/api');
    const countryData = response.data.data[countryCode];

    if (countryData) {
      const countryImage = await fetchCountryImage(countryCode);
      setContent(
        <div className="safety-info-wrapper">
          <h2 className="safety-info-country-name">{countryName}</h2>
          <div className="safety-info-inner-wrapper">
            <img src={countryImage} alt={countryName} className="info_section_image" width='100%' />
            <div className="safety-info-body">
              <span className="photo-reference-info">
                Photo from
                <a className="safety-img-source-link" href={'https://www.pexels.com/'} target="_blank" rel="noopener noreferrer">
                  Pexels
                </a>.
                View
                <a className="safety-img-source-link" href={countryImage} target="_blank" rel="noopener noreferrer">
                  original source.
                </a>
              </span>
              <h3>Safety Advisory</h3>
              <p className="safety-info-message">{countryData.advisory.message}</p>
              <span>
                Source:
                <a className="safety-source-link" href={countryData.advisory.source} target="_blank" rel="noopener noreferrer">
                  Travel Advisory
                </a>
              </span>
            </div>
          </div>
        </div>
      );
      toggleSidebar();
    } else {
      setContent(<p>Sorry, we are unable to show safety data at the moment</p>);
      toggleSidebar();
    }
  } catch (error) {
    setContent(<p>Sorry, we are unable to show safety data at the moment</p>);
    toggleSidebar();
  }
};

const fetchCountryImage = async (countryCode: string): Promise<string> => {
  try {
    const response = await axios.get(`${apiURL}/api/country-image/${countryCode}`);
    const countryObj = response.data;
    return countryObj.photoURL || 'https://images.pexels.com/photos/914929/pexels-photo-914929.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
  } catch (error) {
    return 'https://images.pexels.com/photos/914929/pexels-photo-914929.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
  }
};

export const onCountryHover = (map: mapboxgl.Map) => {
  map.on("mousemove", function (e) {
    if (map.getLayer('safety')) {
      const features = map.queryRenderedFeatures(e.point, { layers: ["safety"] });
      if (features.length) {
        map.getCanvas().style.cursor = 'pointer';
      } else {
        map.getCanvas().style.cursor = '';
      }
    }
  });

  map.on("mouseout", function () {
    map.getCanvas().style.cursor = 'auto';
  });
};