import mapboxgl from 'mapbox-gl';
import { apiURL } from '../const';
import {
  SpotsTypeInterface,
  FetchResult,
  Poi,
  StoredPOI
} from '../interfaces/SpotSelectorInterface';
import { MapboxMap } from 'react-map-gl';
import '../components/map/SpotSelectorMap.scss';

// Mapping POI types to Font Awesome icon classes
const poiTypeIcons: SpotsTypeInterface = {
  "tourist_attraction": "fa-landmark",
  "point_of_interest": "fa-map-pin",
  "night_club": "fa-cocktail",
  "bar": "fa-glass-cheers",
  "event": "fa-calendar-alt",
};

// Fetch and save POIs based on given parameters
export async function fetchAndSavePOIs(
  city: string, iso2Code: string, type: string, lat: number, long: number
): Promise<FetchResult> {
  const url = `${apiURL}/api/pois/${city}?iso2_code=${iso2Code}&type=${type}&lat=${lat}&lng=${long}`;
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return { success: true, data: data };
    } else {
      return { success: false, error: `No POIs found for city ${city}` };
    }
  } catch (error: any) {
    return { success: false, error: `Places search failed: ${error.message}` };
  }
}

// Display POIs on the map
export function displayPOIs(map: mapboxgl.Map, pois: { points_of_interest: Poi[] }) {
  if (pois.points_of_interest.length > 0) {
    pois.points_of_interest.forEach(poi => {
      let lng: number = 0;
      let lat: number = 0;
      if (poi.poi_coords) {
        const coordinates = extractCoordinatesFromWKBHex(poi.poi_coords);
        lat = coordinates.lat;
        lng = coordinates.lng;
      } else if (poi.geometry) {
        lat = poi.geometry.location.lat;
        lng = poi.geometry.location.lng;
      }
      const el = createMarkerElement();

      const types = poi.types || poi.categories || [];
      if (!types.length) return;

      const icon = createIconElement(types);
      el.appendChild(icon);

      const { popupContent, photoPlaceholder, button } = createPopupContent(poi);

      const marker = new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(new mapboxgl.Popup({ offset: 25, className: 'custom-popup' })
          .setDOMContent(popupContent))
        .addTo(map);

      marker.getElement().addEventListener('click',
        (event) => handleMarkerClick(event, marker, poi, photoPlaceholder)
      );

      button.addEventListener('click', () => {
        updateSelectedPOIs(poi, button)
      });
    });

    const clearButton = document.querySelector('.pois-clear-button');
    if (clearButton) {
      (clearButton as HTMLElement).style.display = 'block';
    }
  }
}

export function handlePlaceDetails(map: MapboxMap, data: any) {
  if (data.features.length > 0) {
    const place = data.features[0];
    let city = place.text_en;
    const countryContext = place.context.find(
      (c: { id: string; }) => c.id.startsWith('country')
    );
    const iso2Code = countryContext
      ? countryContext.short_code.toUpperCase()
      : 'Not found';
    const cityCoordinates = place.geometry.coordinates;

    map.flyTo({
      center: cityCoordinates,
      zoom: 10,
      essential: true
    });

    return { city, iso2Code, cityCoordinates };
  } else {
    console.log("No city details found at this location.");
    return null;
  }
}

export async function fetchPlaceDetails(
  long: number, lat: number, accessToken: string
): Promise<any> {
  const response = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${accessToken}&types=place&language=en`
  );
  const data = await response.json();
  return data;
}

function hexToFloat64(hex: string, isLittleEndian: boolean): number {
  // Ensure hex is stripped of any prefix and is of correct length
  if (hex.startsWith('0x')) hex = hex.substring(2);
  if (hex.length !== 16) throw new Error('Invalid hex length for conversion to float64.');

  // Create a buffer from the hex string
  const buffer = new ArrayBuffer(8);
  const dataView = new DataView(buffer);
  for (let i = 0; i < 8; i++) {
    // Parse each byte in hex as a number and set it in the DataView
    // Hex string is parsed in pairs, so multiply index by 2
    const byte = parseInt(hex.substring(i * 2, i * 2 + 2), 16);
    dataView.setUint8(isLittleEndian ? i : 7 - i, byte);
  }

  // Return the float64 representation
  return dataView.getFloat64(0, isLittleEndian);
}

function extractCoordinatesFromWKBHex(wkbHex: string): { lng: number, lat: number } {
  // Check if wkbHex is defined and is a string
  if (typeof wkbHex !== 'string' || wkbHex === undefined) {
    console.error('Invalid or undefined WKB Hex:', wkbHex);
    // Return a default value or handle the error as appropriate
    return { lng: 0, lat: 0 }; // Example default value
  }

  // Remove 'poi_coords: ' prefix if present
  const hex = wkbHex.startsWith('poi_coords: ') ? wkbHex.split(' ')[1] : wkbHex;

  // Check endianness (01 for little endian, 00 for big endian)
  const isLittleEndian = hex.substring(0, 2) === '01';

  // Extract the longitude and latitude parts of the hex string
  // Skipping the first 18 characters (9 bytes: endianness, geometry type)
  // Each coordinate is represented by 16 characters (8 bytes)
  const longitudeHex = hex.substring(18, 34);
  const latitudeHex = hex.substring(34, 50);

  // Convert hex to float64
  const lng = hexToFloat64(longitudeHex, isLittleEndian);
  const lat = hexToFloat64(latitudeHex, isLittleEndian);

  return { lng, lat };
}

// Create marker element
function createMarkerElement(): HTMLDivElement {
  const el = document.createElement('div');
  el.className = 'marker';
  el.style.border = 'none';
  return el;
}

// Create icon element
function createIconElement(types: string[]): HTMLElement {
  const icon = document.createElement('i');
  const iconClass = types
    .map(type => poiTypeIcons[type]).find(icon => icon) || 'fa-map-pin';
  icon.className = `fas ${iconClass}`;
  icon.style.fontSize = '24px';
  icon.style.color = '#ff6347';
  return icon;
}

// Create popup content
function createPopupContent(poi: Poi) {
  const popupContent = document.createElement('div');
  popupContent.className = "poi_popup_info_div";

  const heading = document.createElement('h4');
  heading.textContent = poi.name;

  const photoPlaceholder = document.createElement('div');
  photoPlaceholder.className = 'photo-placeholder';
  photoPlaceholder.textContent = 'Loading photo...';
  photoPlaceholder.style.display = 'block';

  const button = document.createElement('button');
  button.className = "add-to-selected-btn";
  button.setAttribute('data-poi-id', poi.place_id);

  // Check if the POI is already in session storage
  const selectedPOIs = JSON.parse(sessionStorage.getItem('selectedPOIs') || '[]') as StoredPOI[];
  const isPOISelected = selectedPOIs.some(storedPoi => storedPoi.id === poi.place_id);

  button.textContent = isPOISelected ? 'Remove from POIs list' : 'Add to POIs list';
  button.style.backgroundColor = isPOISelected ? 'rgb(179, 0, 0)' : '';
  button.style.color = isPOISelected ? 'white' : '';

  if (poi.imageUrl) {
    const img = document.createElement('img');
    img.src = poi.imageUrl;
    img.alt = poi.name;
    img.className = 'poi-photo';
    photoPlaceholder.textContent = '';
    photoPlaceholder.appendChild(img);
    photoPlaceholder.style.display = 'flex';
  }

  popupContent.appendChild(heading);
  popupContent.appendChild(photoPlaceholder);
  popupContent.appendChild(button);

  return { popupContent, photoPlaceholder, button };
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

// Handle marker click event
async function handleMarkerClick(
  event: MouseEvent, marker: mapboxgl.Marker, poi: Poi, photoPlaceholder: HTMLElement
) {
  event.stopPropagation();
  marker.togglePopup();

  const photoReference = poi.photos?.[0]?.photo_reference;

  const photoURL = photoReference ?
    `${apiURL}/api/pois/photos/reference/${photoReference}` :
    `${apiURL}/api/pois/photos/${poi.place_id}`;

  if (!photoURL) return;

  try {
    const photoResponse = await fetch(photoURL);
    if (photoResponse.ok) {
      const photoData = await photoResponse.blob();
      const base64Image = await convertBlobToBase64(photoData);
      const img = document.createElement('img');
      img.src = base64Image;
      img.alt = poi.name;
      img.className = 'poi-photo';
      photoPlaceholder.textContent = '';
      photoPlaceholder.appendChild(img);
      photoPlaceholder.style.display = 'flex';
      poi.imageUrl = base64Image;
      addPOIPohtoToSessionStorage(poi);
    } else {
      throw new Error('Network response was not ok.');
    }
  } catch (error) {
    console.error('Error fetching or converting photo:', error);
    photoPlaceholder.textContent = 'Error loading photo';
    photoPlaceholder.style.display = 'block';
  }
}

function addPOIPohtoToSessionStorage(poi: Poi) {
  const selectedPOIs = JSON.parse(sessionStorage.getItem('selectedPOIs') || '[]') as StoredPOI[];
  // find POI in session storage
  const storedPOI = selectedPOIs.find(storedPoi => storedPoi.id === poi.place_id);

  // if POI doesn't have a photo add it
  if (storedPOI && !storedPOI.photo) {
    storedPOI.photo = poi.imageUrl;
    sessionStorage.setItem('selectedPOIs', JSON.stringify(selectedPOIs));
    // Trigger storage event
    window.dispatchEvent(new Event('storage'));
  };
}

function addPOIToSessionStorage(poi: Poi) {
  const selectedPOIs = JSON.parse(sessionStorage.getItem('selectedPOIs') || '[]') as StoredPOI[];
  if (!selectedPOIs.some(storedPoi => storedPoi.id === poi.place_id)) {
    const newPOI: StoredPOI = {
      id: poi.place_id,
      name: poi.name,
      photo: poi.imageUrl,
      cityId: poi.city_id
    };
    selectedPOIs.push(newPOI);
    sessionStorage.setItem('selectedPOIs', JSON.stringify(selectedPOIs));
    // Trigger storage event
    window.dispatchEvent(new Event('storage'));
  }
}

// Remove POI from session storage
function removePOIFromSessionStorage(poiId: string) {
  const selectedPOIs = JSON.parse(sessionStorage.getItem('selectedPOIs') || '[]') as StoredPOI[];
  const updatedPOIs = selectedPOIs.filter(poi => poi.id !== poiId);
  sessionStorage.setItem('selectedPOIs', JSON.stringify(updatedPOIs));
  // Trigger storage event
  window.dispatchEvent(new Event('storage'));
}

// Update selected POIs
function updateSelectedPOIs(poi: Poi, btnElement: HTMLElement) {
  const action = btnElement.innerText.includes('Add') ? 'add' : 'remove';
  if (action === 'add') {
    addPOIToSessionStorage(poi);
    btnElement.innerText = 'Remove from POIs list';
    btnElement.style.backgroundColor = 'rgb(179, 0, 0)';
    btnElement.style.color = 'white';
  } else {
    removePOIFromSessionStorage(poi.place_id);
    btnElement.innerText = 'Add to POIs list';
    btnElement.style.backgroundColor = '';
    btnElement.style.color = '';
  }
}