import React from 'react';
import HeaderMenu from '../nav/HeaderMenu';
import InfoSidebar from '../info_sidebar/InfoSidebar';
import { SidebarProvider } from '../info_sidebar/SidebarContext';
import { useIsSpotSelector, useIsSafetyAdvisor } from '../../hooks/useGetPages';
import './Layout.scss';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isSpotSelectorPage = useIsSpotSelector();
  const isSafetyPage = useIsSafetyAdvisor();

  return (
    <SidebarProvider>
      <div className="layout">
        <HeaderMenu />
        <div className="content">
          {(isSafetyPage || isSpotSelectorPage) && <InfoSidebar />}
          <main className="main-content">
            {children}
          </main>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Layout;
