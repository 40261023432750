import React, { useEffect } from 'react';
import { SpotsTypeInterface } from '../../interfaces/SpotSelectorInterface';
import { spotsType } from '../../constants/SpotSelectorType';
import { FaTimes } from 'react-icons/fa';
import { useIsSpotSelector } from '../../hooks/useGetPages';
import './SpotSelectorTypeBar.scss';

const SpotSelectorTypeBar = () => {
  const [selectedSpotTypes, setSelectedSpotTypes] = React.useState<SpotsTypeInterface>({});

  // Get the current page
  const SpotSelectorPage = useIsSpotSelector();

  useEffect(() => {
    const handleTypesStorageUpdate = () => {
      const existingSpotTypes = sessionStorage.getItem('selectedSpotTypes');
      if (existingSpotTypes) {
        const spotTypesArray = JSON.parse(existingSpotTypes);
        const spotTypes = spotTypesArray.reduce((acc: SpotsTypeInterface, type: string) => {
          acc[type] = getSpotType(type);
          return acc;
        }, {});
        setSelectedSpotTypes(spotTypes);
      }
    };

    handleTypesStorageUpdate(); // Initial load
    window.addEventListener('storage', handleTypesStorageUpdate);

    return () => {
      window.removeEventListener('storage', handleTypesStorageUpdate);
    };
  }, []);

  const getSpotType = (key: string) => {
    return spotsType[key];
  }

  const handleDeleteSpotType = (type: string) => {
    const existingSpotTypes = sessionStorage.getItem('selectedSpotTypes');
    const spotTypesArray = existingSpotTypes ? JSON.parse(existingSpotTypes) : [];
    const updatedSpotTypes = spotTypesArray.filter((t: string) => t !== type);

    const spotTypes = updatedSpotTypes.reduce((acc: SpotsTypeInterface, type: string) => {
      acc[type] = getSpotType(type);
      return acc;
    }, {});

    setSelectedSpotTypes(spotTypes);
    sessionStorage.setItem('selectedSpotTypes', JSON.stringify(updatedSpotTypes));
  };

  const createSpotSelectorTypeContainer = () => {
    return (
      <>
        {Object.keys(selectedSpotTypes).map((key: string) => (
          <div key={key} data-key={key} className='spot-type'>
            <div className='spot-type-wrapper'>
              <span>{spotsType[key]}</span>
              <FaTimes onClick={() => handleDeleteSpotType(key)} className='spot-type-delete-button' />
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {selectedSpotTypes && Object.keys(selectedSpotTypes).length > 0 && SpotSelectorPage &&
        <header className="spots-type-bar">
          {createSpotSelectorTypeContainer()}
        </header>
      }
    </>
  );
};

export default SpotSelectorTypeBar;