import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SidebarContextProps } from '../../interfaces/SidebarInterface';

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [height, setHeight] = useState(60);
  const [content, setContent] = useState<ReactNode>(null);

  const toggleSidebar = () => setVisible(!visible);

  return (
    <SidebarContext.Provider value={
      { visible, toggleSidebar, height, setHeight, content, setContent }
    }>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
