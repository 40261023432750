import React, { useRef } from 'react';
import BaseMap from './BaseMap';
import { fetchAndSavePOIs, displayPOIs, handlePlaceDetails, fetchPlaceDetails } from '../../services/SpotSelectorService';
import './SpotSelectorMap.scss';

const SpotSelectorMap: React.FC = () => {
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const handleMapClick = async (map: mapboxgl.Map, e: mapboxgl.MapMouseEvent) => {
    if (!e || !e.lngLat) {
      return;
    }
    const long = e.lngLat.lng;
    const lat = e.lngLat.lat;

    try {
      const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';
      const data = await fetchPlaceDetails(long, lat, accessToken);
      const placeDetails = handlePlaceDetails(map, data);

      const types = getSpotTypesFromSessionStorage();

      if (!types || types.length === 0) {
        // TODO: Use error popup
        console.error('No spot types selected');
        return;
      }

      if (placeDetails) {
        const { city, iso2Code } = placeDetails;
        const pois = await fetchAndSavePOIs(city, iso2Code, types, lat, long);
        if (pois.success) {
          displayPOIs(map, pois.data);
        } else {
          // TODO: Use error popup
          console.error(pois.error);
        }
      }
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };
  
  const handleOnCitiesHover = (map: mapboxgl.Map) => {
    map.on("mousemove", function (e) {
      if (map.getLayer('settlement-label')) {
        const features = map.queryRenderedFeatures(e.point, { layers: ["settlement-label"] });
        // console.log(features);
        if (features.length) {
          map.getCanvas().style.cursor = 'pointer';
        } else {
          map.getCanvas().style.cursor = '';
        }
      }
    });
  
    map.on("mouseout", function () {
      map.getCanvas().style.cursor = 'auto';
    });
  };

  const getSpotTypesFromSessionStorage = () => {
    const spotTypes = sessionStorage.getItem('selectedSpotTypes');
    if (spotTypes) {
      return JSON.parse(spotTypes);
    }
    return [];
  };

  return (
    <BaseMap
      initialTheme="dark"
      center={[-74.5, 40]}
      zoom={1.5}
      maxZoom={15}
      onMapLoad={(map) => {
        mapRef.current = map;
        const handleClick = (e: mapboxgl.MapMouseEvent) => handleMapClick(map, e);
        map.on('click', handleClick);
        
        handleOnCitiesHover(map);

        return () => {
          map.off('click', handleClick);
        };
      }}
    />
  );
};

export default SpotSelectorMap;