import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import Layout from './components/layout/Layout';
import Safety from './pages/Safety';
import SpotSelector from './pages/SpotSelector';
import StayBooking from './pages/StayBooking';
import FlightBooking from './pages/FlightBooking';
import { initGA, logPageView } from "./analytics/GoogleAnalytics";
import './App.css';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    initGA(); // Initialize GA on first load
  }, []);

  useEffect(() => {
    logPageView(location.pathname); // Log every route change
  }, [location]);

  return (
      <Layout>
        <Routes>
          <Route path="/" element={<SpotSelector />} />
          <Route path="/safety_advisor" element={<Safety />} />
          <Route path="/spot_selector" element={<SpotSelector />} />
          <Route path="/stay_booking" element={<StayBooking />} />
          <Route path="/flight_booking" element={<FlightBooking />} />
        </Routes>
      </Layout>
  );
};

export default App;
