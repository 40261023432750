import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const pages = [
  '',
  'spot_selector',
  'safety_advisor',
  'stay_booking',
  'flight_booking',
  'contact'
];

export const getCurrentPage = (pathname: string) => {
  const page = pathname.split('/')[1];
  return page;
}

export const useGetPages = () => {
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(getCurrentPage(location.pathname));
  useEffect(() => {
    setCurrentPage(getCurrentPage(location.pathname));
  }, [window.location.pathname]);

  return { currentPage };
}

export const useIsSpotSelector = () => {
  const { currentPage } = useGetPages();
  return currentPage === pages[0] || currentPage === pages[1];
}

export const useIsSafetyAdvisor = () => {
  const { currentPage } = useGetPages();
  return currentPage === pages[2];
}

export const useIsStayBooking = () => {
  const { currentPage } = useGetPages();
  return currentPage === pages[3];
}

export const useIsFlightBooking = () => {
  const { currentPage } = useGetPages();
  return currentPage === pages[4];
}

export const useIsContact = () => {
  const { currentPage } = useGetPages();
  return currentPage === pages[5];
}