import ReactGA from "react-ga4";

const trackingId = "G-QQ1D72PCMG";
export const initGA = () => {
  ReactGA.initialize(trackingId);
};

export const logPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
