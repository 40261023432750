import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDeviceType } from '../../hooks/useGetDeviceType';
import { useSidebar } from './SidebarContext';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { InfoSidebarProps, StoredPOI } from '../../interfaces/SpotSelectorInterface';
import { useIsSpotSelector } from '../../hooks/useGetPages';
import Button from '@mui/joy/Button';
import './InfoSidebar.scss';


const InfoSidebar: React.FC<InfoSidebarProps> = () => {
  const { visible, toggleSidebar, content } = useSidebar();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(500);
  const [sidebarHeight, setSidebarHeight] = useState(500);
  const [storedPOIs, setStoredPOIs] = useState<StoredPOI[]>([]);

  // Get the current location
  const location = useLocation();

  // State to track if the app is being viewed on a mobile device
  const { isMobile } = getDeviceType();

  // Get the current page
  const isSpotSelectorPage = useIsSpotSelector();

  // Function to start resizing
  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  // Function to stop resizing
  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  // Function to handle the resizing logic
  const resize = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (isResizing) {
        if (sidebarRef.current) {
          let newWidth, newHeight;
          if (event instanceof MouseEvent) {
            newWidth = event.clientX - sidebarRef.current.getBoundingClientRect().left;
            newHeight = sidebarRef.current.getBoundingClientRect().bottom - event.clientY;
          } else {
            const touch = event.touches[0];
            newWidth = touch.clientX - sidebarRef.current.getBoundingClientRect().left;
            newHeight = sidebarRef.current.getBoundingClientRect().bottom - touch.clientY;
          }
          if (isMobile) {
            setSidebarHeight(newHeight);
          } else {
            setSidebarWidth(newWidth);
          }
        }
      }
    },
    [isResizing, isMobile]
  );

  // Effect to handle storage updates
  useEffect(() => {
    const handleStorageUpdate = () => {
      const pois = JSON.parse(sessionStorage.getItem('selectedPOIs') || '[]') as StoredPOI[];
      setStoredPOIs(pois);
    };

    handleStorageUpdate(); // Initial load
    window.addEventListener('storage', handleStorageUpdate);

    return () => {
      window.removeEventListener('storage', handleStorageUpdate);
    };
  }, []);

  // Add event listeners for mousemove, mouseup, touchmove, and touchend events
  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    window.addEventListener('touchmove', resize);
    window.addEventListener('touchend', stopResizing);

    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
      window.removeEventListener('touchmove', resize);
      window.removeEventListener('touchend', stopResizing);
    };
  }, [resize, stopResizing]);

  const handleRemoveListItem = (id: string) => {
    const updatedPOIs = storedPOIs.filter(poi => poi.id !== id);
    sessionStorage.setItem('selectedPOIs', JSON.stringify(updatedPOIs));
    setStoredPOIs(updatedPOIs);

    // Find the corresponding button and update its text
    const button = document.querySelector(`button[data-poi-id="${id}"]`);
    if (button) {
      (button as HTMLButtonElement).innerText = 'Add to POIs list';
      (button as HTMLButtonElement).style.backgroundColor = '';
      (button as HTMLButtonElement).style.color = '';
    }
  };

  const handleClearPOIsList = () => {
    setStoredPOIs([]);
    sessionStorage.removeItem('selectedPOIs');
    handleSetAllPOIsButtonsToDefault();
  }

  const handleSetAllPOIsButtonsToDefault = () => {
    const buttons = document.querySelectorAll('.add-to-selected-btn');
    buttons.forEach(button => {
      (button as HTMLButtonElement).innerText = 'Add to POIs list';
      (button as HTMLButtonElement).style.backgroundColor = '';
      (button as HTMLButtonElement).style.color = '';
    });
  }

  const createPOIsMenu = (pois: StoredPOI[]) => {
    return (
      <>
        <div className='pois-list'>
          <div className='pois-list-body'>
            <div className='pois-list-heading'>
              <h2 className='pois-list-heading-title'>Selected Spots</h2>
            </div>
            <ul className="pois-list-ul">
              {pois.map(poi => (
                <li key={poi.id} className="poi-item"
                  style={{
                    width: isMobile ? '' : '100%',
                  }}>
                  <button className="delete-list-item-btn" onClick={() => handleRemoveListItem(poi.id)}>
                    <FaTrash />
                  </button>
                  <h4 className='poi-list-name'>{poi.name}</h4>
                  {poi.photo && <img className='poi-list-image' src={poi.photo} alt={poi.name} />}
                </li>
              ))}
            </ul>
          </div>
          <div className='pois-list-footer'>
            <Button
              className='pois-list-book-accommodation-btn'
              onClick={() => handleNavigation('/flight_booking')}
              size="lg"
              variant="solid"
            >
              Book Flights
            </Button>
            <Button
              className='pois-list-book-accommodation-btn'
              onClick={() => handleNavigation('/stay_booking')}
              size="lg"
              variant="solid"
            >
              Book Hotels
            </Button>
          </div>
        </div>
      </>
    );
  };

  // Conditionally render the sidebar based on the length of storedPOIs
  if (isSpotSelectorPage && storedPOIs.length === 0) {
    return null;
  }

  // TODO: Implement the sidebar kebab menu
  // const handleShowSidebarKebabMenu = () => {
  // }

  const handleNavigation = (path: string) => {
    if (location.pathname !== path) {
      window.location.href = path;
    } else {
      window.location.reload();
    }
  };

  return (
    <div className={isMobile ? 'app-container-portable' : 'app-container'}>
      {visible && (
        <div
          ref={sidebarRef}
          className={isMobile ? 'app-sidebar-portable' : visible ? 'app-sidebar app-sidebar-visible' : 'app-sidebar'}
          style={
            isMobile ?
              { height: sidebarHeight, width: '100vw' } :
              { width: sidebarWidth }
          }
          onMouseDown={(e) => e.preventDefault()} // Prevent text selection while resizing
        >
          <div className='app-sidebar-content'>
            <div className={isSpotSelectorPage ? 'app-sidebar-header-spot-selector' : 'app-sidebar-header'}>
              {isSpotSelectorPage &&
                <Button
                  color="neutral"
                  onClick={handleClearPOIsList}
                  size="md"
                  variant="solid"
                >
                  Clear List
                </Button>
              }
              <button className="close-btn" onClick={toggleSidebar}>
                <FaTimes />
              </button>
            </div>
            {isSpotSelectorPage ? createPOIsMenu(storedPOIs) : content}
          </div>
          <div
            className={
              isMobile ? 'app-sidebar-resizer-wrapper-portable' : 'app-sidebar-resizer-wrapper'
            }
            onMouseDown={startResizing}
            onTouchStart={startResizing}
          >
            <div
              className={
                isMobile ? 'app-sidebar-resizer-portable' : 'app-sidebar-resizer'
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoSidebar;
