import React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.scss';

const NavMenu: React.FC = () => {
  const location = useLocation();

  const getPageName = (path: string) => {
    switch (path) {
      case '/spot_selector':
        return 'Spot Selector';
      case '/safety_advisor':
        return 'Safety Advisor';
      case '/contact':
        return 'Contact';
      case '/stay_booking':
        return 'Stay Booking';
      case '/flight_booking':
        return 'Flight Booking';
      default:
        return 'Navigate';
    }
  };

  const currentPageName = getPageName(location.pathname);

  const handleNavigation = (path: string) => {
    if (location.pathname !== path) {
      window.location.href = path;
    } else {
      window.location.reload();
    }
  };

  return (
    <Dropdown>
      <MenuButton variant="solid">{currentPageName}</MenuButton>
      <Menu variant="solid">
        <MenuItem onClick={() => handleNavigation('/spot_selector')}>
          <Link className='nav-link' to="/spot_selector">Spot Selector</Link>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/safety_advisor')}>
          <Link className='nav-link' to="/safety_advisor">Safety Advisor</Link>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/stay_booking')}>
          <Link className='nav-link' to="/stay_booking">Stay Booking</Link>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/flight_booking')}>
          <Link className='nav-link' to="/flight_booking">Flight Booking</Link>
        </MenuItem>
        <MenuItem onClick={() => handleNavigation('/contact')}>
          <Link className='nav-link' to="/contact">Contact</Link>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default NavMenu;