import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { BaseMapRef, BaseMapProps } from '../../interfaces/BaseMapInterface';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './BaseMap.scss';

const BaseMap = forwardRef<BaseMapRef, BaseMapProps>(({
  initialTheme = 'dark',
  center = [-74.5, 40],
  zoom = 1.5,
  maxZoom = 8,
  onMapLoad,
  onMapClick,
}, ref) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [currentTheme, setCurrentTheme] = useState<'dark' | 'light' | null>(initialTheme);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: currentTheme === 'light'
        ? 'mapbox://styles/mapbox/streets-v11'
        : 'mapbox://styles/mapbox/dark-v10',
      center,
      zoom,
      maxZoom
    });

    map.current.on('load', () => {
      if (onMapLoad) {
        onMapLoad(map.current!);
      }

      // Add the layer after the map has fully loaded
      if (!map.current!.getLayer('place-city-lg-n')) {
        map.current!.addLayer({
          'id': 'place-city-lg-n',
          'type': 'circle',
          'source': {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': []
            }
          },
          'paint': {
            'circle-radius': 6,
            'circle-color': '#B42222'
          }
        });
      } else {
        console.log('Layer already exists');
      }
    });

    map.current.on('click', (e) => {
      if (onMapClick) {
        onMapClick(map.current!, e);
      }
    });
  }, [currentTheme, center, zoom, maxZoom, onMapLoad, onMapClick]);

  useImperativeHandle(ref, () => ({
    addLayerAndListeners: (layerId: string, layerConfig: mapboxgl.Layer) => {
      if (!map.current) return;

      if (!map.current.getLayer(layerId)) {
        map.current.addLayer(layerConfig);
      }

      map.current.on('mouseenter', layerId, () => {
        map.current!.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('mouseleave', layerId, () => {
        map.current!.getCanvas().style.cursor = '';
      });
    }
  }));

  return <div ref={mapContainer} id='map' className="map-container" />;
});

export default BaseMap;