import React from 'react';
import { getDeviceType } from '../../../hooks/useGetDeviceType';
import './TripFlightBookingWidget.scss';

const FlightBookingWidget: React.FC = () => {
  // State to track if the app is being viewed on a mobile device
  const { isPortable } = getDeviceType();
  
  return (
    <div className='flight-booking-widget-wrapper'>
      <iframe
        className={isPortable ? "flight-booking-widget-portable" : "flight-booking-widget"}
        src="https://www.trip.com/partners/ad/S625711?Allianceid=5458846&SID=119452187&trip_sub1="
        id="S625711"
      >
      </iframe>
    </div>
  );
};

export default FlightBookingWidget;