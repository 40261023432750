import { SpotsTypeInterface } from '../interfaces/SpotSelectorInterface';

export const spotsType: SpotsTypeInterface = {
  "tourist_attraction": "Tourist Attractions",
  "point_of_interest": "Points of Interest",
  "night_club": "Night Clubs",
  "bar": "Bars",
  "event": "Events",
};

export const handleSpotTypeExistance = (type: string) => {
  return Object.values(spotsType).includes(type);
}
