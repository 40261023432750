import React from 'react';
import BaseMap from './BaseMap';
import { fetchAndDisplaySafetyData, handleCountryClick, onCountryHover } from '../../services/SafetyService';
import { useSidebar } from '../info_sidebar/SidebarContext';
import './SafetyMap.scss';

const SafetyMap: React.FC = () => {
  const { setContent, toggleSidebar } = useSidebar();

  const handleMapLoad = (map: mapboxgl.Map) => {
    fetchAndDisplaySafetyData(map);
    onCountryHover(map);
  };

  const handleMapClick = (map: mapboxgl.Map, event: mapboxgl.MapMouseEvent) => {
    handleCountryClick(map, event, setContent, toggleSidebar);
  };

  return (
    <BaseMap
      initialTheme="dark"
      center={[-74.5, 40]}
      zoom={1.5}
      maxZoom={8}
      onMapLoad={handleMapLoad}
      onMapClick={handleMapClick}
    />
  );
};

export default SafetyMap;